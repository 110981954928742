import { LOCALES } from '../locales';

export default {
  [LOCALES.SPANISH]: {
    main_header: 'COVID-19 es responsabilidad de todos',
    sub_header: 'Mantente informado.',
    sub_sub_header: 'Estadísticas mundiales de COVID-19',
    total_cases: 'Total:',
    cases: 'de casos',
    recovered: 'Recuperados:',
    deaths: 'Muertes:',
    search_by_country: 'Busca por país',
    new_cases: 'Casos nuevos',
    new_deaths: 'Casos nuevos de muertes',
    active_cases: 'Casos activos',
    recovered_cases: 'Casos de recuperaciones',
    death_cases: 'Casos de muertes',
    serious_critical: 'Casos Críticos',
    cases_per_1M: 'Casos totales por 1M/pop',
    total_test: 'Pruebas tomadas',
    date: 'Fecha: ',
    date_table: 'Ultimos 14 días',
    num_cases: 'Número de casos',
    no_data: 'No hay datos',
    graph_title: 'Evolución de casos en los últimos 14 días',
    total_graph: 'Total de casos',
    recovered_table: 'Casos de recuperaciones',
    death_table: 'Casos de muertes',
    search_other: 'Busca otro país',
    go_back: 'Regresar',
    more_details: 'Más detalles',
    graphs: 'Gráficas',
    graph_total: 'Evolución del TOTAL de casos en el último mes',
    graph_recovered: 'Evolución de casos de RECUPERACIONES en el último mes',
    graph_death: 'Evolución de casos de MUERTE en el último mes'
  },
};

import React, { Component } from 'react';

export default class Loading extends Component {
  render() {
    return (
      <div
        className='col-12 d-flex justify-content-center align-items-center'
        style={{ height: '100vh' }}
      >
        <img
          src={require('../images/loading_spinner.gif')}
          alt='loading page'
          className='d-flex justify-content-center'
          style={{ maxHeight: '10rem' }}
        />
      </div>
    );
  }
}

import { LOCALES } from '../locales';

export default {
  [LOCALES.ENGLISH]: {
    main_header: 'COVID-19 concerned us all',
    sub_header: 'Stay informed.',
    sub_sub_header: 'Live world statistic on COVID-19',
    total_cases: 'Total',
    cases: 'cases',
    recovered: 'Recovered',
    deaths: 'Deaths',
    search_by_country: 'Search by country',
    new_cases: 'New cases',
    new_deaths: 'New deaths',
    active_cases: 'Active cases',
    recovered_cases: 'Recovered cases',
    death_cases: 'Death cases',
    serious_critical: 'Serious Critical',
    cases_per_1M: 'Total Cases per 1M/pop',
    total_test: 'Total tests',
    date: 'Date: ',
    num_cases: '# of cases',
    no_data: 'No data',
    graph_title: 'Evolution of cases in the last 14 days',
    total_graph: 'Total cases',
    recovered_table: 'Recovered cases',
    death_table: 'Deaths cases',
    search_other: 'Search another',
    go_back: 'Go back',
    more_details: 'More details',
    graphs: 'Graphs',
    graph_total: 'Evolution of TOTAL cases in the last month',
    graph_recovered: 'Evolution of RECOVERED cases in the last month',
    graph_death: 'Evolution of DEATHS cases in the last month'
  },
};

import { LOCALES } from '../locales';

export default {
  [LOCALES.FRENCH]: {
    main_header: 'COVID-19 nous concerne tous',
    sub_header: 'Rester informé.',
    sub_sub_header: 'Statistiques mondiales en direct sur COVID-19',
    total_cases: 'Nombre total',
    cases: 'de cas',
    recovered: 'Récupérer',
    deaths: 'Décès',
    search_by_country: 'Recherche par pays',
    new_cases: 'Nouveaux cas',
    new_deaths: 'Nouveaux décès',
    active_cases: 'Cas actifs',
    recovered_cases: 'Cas récupérés',
    death_cases: 'Cas de décès',
    serious_critical: 'Critique grave',
    cases_per_1M: 'Total des cas par 1M / pop',
    total_test: 'Tests totaux',
    date: 'Date: ',
    num_cases: 'Nombre de cas',
    no_data: 'Pas de données',
    graph_title: 'Evolution des cas au cours des 14 derniers jours',
    total_graph: 'Nombre total de cas',
    recovered_table: 'Cas récupérés',
    death_table: 'Cas de décès',
    search_other: 'Rechercher un autre',
    go_back: 'Retourner',
    more_details: 'Plus de détails',
    graphs: 'Graphiques',
    graph_total: 'Evolution du nombre total de cas au cours du dernier mois',
    graph_recovered: 'Évolution des cas RÉCUPÉRÉS au cours du dernier mois',
    graph_death: 'Evolution des cas de DÉCÈS au cours du dernier mois'
  },
};

import React, { Component } from 'react';
import translate from '../i18n/translate';

export default class TableCountry extends Component {
  render() {
    return (
      <div className='col-12'>
        <div className="row d-flex justify-content-center align-items-center mb-3">
          <span className="icon">
            <img src={require('../images/notes_img.png')} alt="virus"/>
          </span>
          <h3>{translate('more_details')}</h3>
        </div>

        <div className="col-lg-6 offset-lg-3 mt-4">
        <table className='table table-striped text-center'>
          <tbody>
              <tr>
                <th>{translate('new_cases')}</th>
                  <td>{this.props.newCases ? `+${this.props.newCases}` : translate('no_data') }</td>
              </tr>
              <tr>
                <th>{translate('new_deaths')}</th>
                  <td>{this.props.newDeaths ? `+${this.props.newDeaths}` : translate('no_data')}</td>
              </tr>
              <tr>
                <th>{translate('active_cases')}</th>
                  <td>{this.props.active ? this.props.active : translate('no_data')}</td>
              </tr>
              <tr>
                <th>{translate('serious_critical')}</th>
                  <td>{this.props.critical ? this.props.critical : translate('no_data')}</td>
              </tr>
              <tr>
                <th>{translate('cases_per_1M')}</th>
                  <td>{this.props.totalPer1m ? this.props.totalPer1m : translate('no_data')}</td>
              </tr>
              <tr>
                <th>{translate('total_test')}</th>
                  <td>{this.props.tests ? this.props.tests : translate('no_data')}</td>
              </tr>
          </tbody>
        </table>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import TableCountry from './tableCountry';
import GraphCountry from './graphCountry';
import translate from '../i18n/translate';
import Loading from './loading';
import moment from 'moment';
import './country.css';

class Country extends Component {
  constructor(props) {
    super(props);
    this.localUrl = 'http://127.0.0.1:5001/covid-chart-4d5a3/us-central1/app';
    this.prodUrl = 'https://us-central1-covid-chart-4d5a3.cloudfunctions.net/app';
    this.state = {
      inLoaded: false,
      countryName: "",
      total: null,
      recovered: null,
      deaths: null,
      newCases: null,
      newDeaths: null,
      activeCases: null,
      seriousCritical: null,
      totalCasesPer1M: null,
      totalTests: null,
      statsLast30Days: [],
    }
  }

  async loadDataOfCountry(countryName) {
    fetch(`${this.prodUrl}/stats-by-country/${countryName}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            countryName: result.country,
            total: result.latest_stat_by_country.cases,
            recovered: result.latest_stat_by_country.recovered,
            deaths: result.latest_stat_by_country.deaths,
            newCases: result.latest_stat_by_country.todayCases,
            newDeaths: result.latest_stat_by_country.todayDeaths,
            activeCases: result.latest_stat_by_country.active,
            seriousCritical: result.latest_stat_by_country.critical,
            totalCasesPer1M: result.latest_stat_by_country.casesPerOneMillion,
            totalTests: result.latest_stat_by_country.tests
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        }
      )
      .then(() => this.getLatestStatsPerDay());
  }

  async componentDidMount(){
    const {countryName} = this.props.match.params
    await this.loadDataOfCountry(countryName);
  }

  async componentWillReceiveProps(nextProps){
    const newCountry = nextProps.match.params.countryName
    await this.loadDataOfCountry(newCountry);
    window.scrollTo(0, 0)
  }

/* Function returns last 15 days in format YYYY-MM-DD */
  getLast30days(){
    let arrOfDays = [];
    for(let i = 0; i < 30; i++){
      arrOfDays.push(moment().subtract(i, 'days').format('YYYY-MM-DD'))
    }
    return arrOfDays;
  }

/* Function returns info from LAST MONTH such as active, death and recovered cases */
  getLatestStatsPerDay(){
    if(this.state.countryName){
      /* Production URL */
      fetch(`${this.prodUrl}/stats-by-country-pro/${this.state.countryName}`)
      /* Local URL */
      //fetch(`http://localhost:5001/covid-chart-4d5a3/us-central1/app/stats-by-country-pro/${this.state.countryName}`)
      .then(res => res.json())
      .then(
        (result) => {
        this.setState({ statsLast30Days: result })
      });
    };
  }

  render(){
    if(!this.state.isLoaded) {
      return <Loading />
    } else {

      return (
        <div className="col-12 bg-light">

          <div className="row">
            <div className="col-12 p-3 text-center">
              <h1 className="country-name">{this.state.countryName}</h1>
            </div>
          </div>

          <div className="row d-flex justify-content-around m-2">

            <div className="card country-info bg-white border-white shadow-sm border-white shadow-sm">
              <div className="card-body d-flex">

                <div className="col-10 d-flex flex-column align-items-start justify-content-center">
                    <h6>{translate('total_graph')}</h6>
                  <h3 className="number">{this.state.total}</h3>
                </div>

                <div className="col-2">
                  <span className="icon">
                    <img src={require('../images/total_img.png')} alt="files"/>
                  </span>
                </div>
              </div>
            </div>

            <div className="card country-info bg-white border-white shadow-sm">
              <div className="card-body d-flex">

                <div className="col-10 d-flex flex-column align-items-start justify-content-center">
                  <h6>{translate('recovered_cases')}</h6>
                  <h3 className="number">{this.state.recovered}</h3>
                </div>

                <div className="col-2">
                  <span className="icon">
                    <img src={require('../images/recovered_img.png')} alt="recovered man"/>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-3 m-2 d-flex justify-content-around">
            <div className="card country-info bg-white border-white shadow-sm">
              <div className="card-body d-flex">

                <div className="col-10 d-flex flex-column align-items-start justify-content-center">
                  <h6>{translate('death_cases')}</h6>
                  <h3 className="number">{this.state.deaths}</h3>
                </div>

                <div className="col-2 align-end">
                  <span className="icon">
                    <img src={require('../images/death_img.png')} alt="deaths cases"/>
                  </span>
                </div>
              </div>
            </div>

            <div className="card country-info bg-white border-white shadow-sm">
              <div className="card-body d-flex">

                <div className="col-10 d-flex flex-column align-items-start justify-content-center">
                  <h6>{translate('active_cases')}</h6>
                  <h3 className="number">{this.state.activeCases}</h3>
                </div>

                <div className="col-2">
                  <span className="icon">
                    <img src={require('../images/virus_img.jpg')} alt="virus"/>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <TableCountry newCases={this.state.newCases} newDeaths={this.state.newDeaths} active={this.state.activeCases} critical={this.state.seriousCritical} totalPer1m={this.state.totalCasesPer1M} tests={this.state.totalTests}/>
          </div>

          <div className="row my-3">
            <div className="container bg-white shadow-sm rounded my-2">
              <GraphCountry latestStats30days={this.state.statsLast30Days}/>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default Country;

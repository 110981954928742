import React, { Component } from 'react';
import Routes from './routes';

import { I18nProvider, LOCALES } from '../src/i18n';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: LOCALES.ENGLISH,
      buttonTitle: 'Select language',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  getDefaultLanguage() {
    const language = window.navigator.language.split('-')[0];
    switch (language) {
      case 'en':
        this.setState({
          language: LOCALES.ENGLISH,
        });
        break;
      case 'es':
        this.setState({
          language: LOCALES.SPANISH,
        });
        break;
      case 'fr':
        this.setState({
          language: LOCALES.FRENCH,
        });
        break;
      default:
        this.setState({
          language: LOCALES.ENGLISH,
        });
        break;
    }
  }

  componentDidMount() {
    this.getDefaultLanguage();
  }

  handleChange(e) {
    switch (e.target.value) {
      case 'English':
        this.setState({
          language: LOCALES.ENGLISH,
          buttonTitle: e.target.value,
        });
        break;
      case 'Español':
        this.setState({
          language: LOCALES.SPANISH,
          buttonTitle: e.target.value,
        });
        break;
      case 'Français':
        this.setState({
          language: LOCALES.FRENCH,
          buttonTitle: e.target.value,
        });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div className='App'>
        <I18nProvider locale={this.state.language}>
          <div className='d-flex container-fluid justify-content-end p-3 nav-bar'>
            <DropdownButton
              id='dropdown-item-button'
              title={this.state.buttonTitle}
            >
              <Dropdown.Item
                as='button'
                value='English'
                onClick={this.handleChange}
              >
                English
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                value='Español'
                onClick={this.handleChange}
              >
                Español
              </Dropdown.Item>
              <Dropdown.Item
                as='button'
                value='Français'
                onClick={this.handleChange}
              >
                Français
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <Routes />
        </I18nProvider>
      </div>
    );
  }
}

export default App;

import React, { Component } from 'react';
import Loading from './loading';
import './home.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import translate from '../i18n/translate';

class Home extends Component {

  constructor(props) {
    super(props);
    this.localUrl = 'http://127.0.0.1:5001/covid-chart-4d5a3/us-central1/app';
    this.prodUrl =  'https://us-central1-covid-chart-4d5a3.cloudfunctions.net/app';
    this.state = {
      error: null,
      isLoaded: false,
      total: null,
      recovered: null,
      death: null,
      countries: [],
    };
  }
  componentDidMount() {
    fetch(
      // 'https://us-central1-covid-chart-4d5a3.cloudfunctions.net/app/global-stats'
      // 'http://127.0.0.1:5001/covid-chart-4d5a3/us-central1/app/global-stats'
      this.prodUrl+ '/global-stats'
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            total: result.total_cases,
            recovered: result.total_recovered,
            death: result.total_deaths,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    /* fetching names of countries to be used in typeahead */
    fetch(
      // 'https://us-central1-covid-chart-4d5a3.cloudfunctions.net/app/stats-all-countries'
      this.prodUrl+ '/affected-countries'
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          countries: this.state.countries.concat(data.affected_countries),
        });
      });
  }
  render() {
    const { total, recovered, death } = this.state;
    if (!this.state.isLoaded) {
      return <Loading />;
    } else {
      return (
        <div className="col-12">
          <div className="row">

            <div className='col-md-9 col-7 py-3 d-flex flex-column justify-content-end  header'>
              <h1 className="font-weight-bold">{translate('main_header')}</h1>
              <h3>{translate('sub_header')}</h3>
            </div>
            <div className='col-md-3 col-5 d-flex justify-content-end align-items-center'>
              <img
                src={require('../images/covid-logo.png')}
                alt='covid-icon'
                className='img-fluid covid-logo'
              />
            </div>
          </div>

          <div className="row bg-light shadow-sm d-flex flex-column align-items-center p-4">
            <div className="col-12 text-center p-3">
              <h5 className="font-weight-bold">{translate('sub_sub_header')}</h5>
            </div>

            <div className="col-12 d-sm-flex">
              <div className="col-12 col-sm-4">
                <div className="card cases-info p-2 mb-2">
                  <div className="card-body text-center text-white">
                    <h4>{translate('total_cases')}</h4>
                    <p className="card-number">{total}</p>
                    <h5>{translate('cases')}</h5>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="card cases-info p-2 mb-2">
                  <div className="card-body text-center text-white">
                    <h4>{translate('recovered')}</h4>
                    <p className="card-number">{recovered}</p>
                    <h5>{translate('cases')}</h5>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="card cases-info p-2">
                  <div className="card-body text-center text-white">
                    <h4>{translate('deaths')}</h4>
                    <p className="card-number">{death}</p>
                    <h5>{translate('cases')}</h5>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className='row d-flex flex-column align-items-center p-3 form-group'>
            <div className='col-10 col-md-4 col-lg-3 text-center p-3'>
              <h5 className="font-weight-bold"> {translate('search_by_country')} </h5>

              <Typeahead
                id={1}
                onChange={(selected) => {
                  this.props.history.push(`/${selected[0]}`);
                }}
                options={this.state.countries || []}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Home;

import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from './components/home';
import Country from './components/country';

export default class Routes extends Component {
  render(){
    return(
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/:countryName" component={Country}/>
      </Switch>
    )
  }
}

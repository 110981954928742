import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import ResponsiveOrdinalFrame from "semiotic/lib/ResponsiveOrdinalFrame"
import { Typeahead } from 'react-bootstrap-typeahead';
import translate from '../i18n/translate';
import './graphCountry.css';
const theme = ["#ac58e5","#E0488B","#9fd0cb","#e0d33a","#7566ff","#533f82","#7a255d","#365350","#a19a11","#3f4482"];

class GraphCountry extends Component {
  constructor(props){
    super(props);
    this.localUrl = 'http://127.0.0.1:5001/covid-chart-4d5a3/us-central1/app';
    this.prodUrl = 'https://us-central1-covid-chart-4d5a3.cloudfunctions.net/app';
    this.state = {
      countries: [],
      redirect: false
    }
  }
  componentDidMount(){
    fetch(`${this.prodUrl}/affected-countries`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            countries: result.affected_countries,
            redirect: true
          })
        }
      );
  }

  getGraphData(graphName){
    let stats = this.props.latestStats30days;
    let data = [];

    for(let i = 0; i < stats.length; i++){
      if(stats[i] !== undefined){
        let date = new Date(stats[i].Date);
        let formattedDate = date.getMonth()+1+"/" + date.getDate();

        data.push({
          /* totalCases is a number without commas, totalForTooltip is a string with commas */
          totalCases: stats[i][graphName],
          totalForTooltip: stats[i][graphName].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          date: formattedDate
        })
      }
    }

    return ({data: data});
  }

  /* Function that gets data every 3 days from last month to show it on smaller devices */
  getGraphDataEvery3Days(graphName){
    let stats = this.props.latestStats30days;
    let data = [];

    for (let i = 0; i < stats.length; i++){
      if(i % 3 === 0){
        let date = new Date(stats[i].Date);
        let formattedDate = date.getMonth()+1+"/" + date.getDate();

        data.push({
          /* totalCases is a number without commas, totalForTooltip is a string with commas */
          totalCases: stats[i][graphName],
          totalForTooltip: stats[i][graphName].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          date: formattedDate
        })
      }
    }
    return ({data: data});
  }

  /* Function to customize each graph like "title" and "color of stroke" */
  getGraphSpecificProperties(graphName){
    let graphProps = {}

    if(graphName === 'totalCases'){
      graphProps.style = {
        fill: theme[4],
        stroke: 'white',
      }
      graphProps.title = (
        <text textAnchor="middle">
          {translate('graph_total')}
        </text>
      );
    } else if (graphName === 'recoveredCases'){
      graphProps.style = {
        fill: theme[1],
        stroke: 'white',
      }
      graphProps.title = (
        <text textAnchor="middle">
          {translate('graph_recovered')}
        </text>
      );
    } else if (graphName === 'deathsCases'){
      graphProps.style = {
        fill: theme[2],
        stroke: 'white',
      }
      graphProps.title = (
        <text textAnchor="middle">
          {translate('graph_death')}
        </text>
      );
    }
    return graphProps;
  }

  /* This function returns other common properties needed in Props object for the Graph */
  getGraphCommonProps(){
    let frameProps = {

      size: [300, 300],

      /* --- Layout --- */
      type: "clusterbar",
      oPadding: 10,

      /* --- Process --- */
      oAccessor: "date",
      rAccessor: "totalCases",

      /* --- Customize --- */
      pieceHoverAnnotation: true,
      axes: [{
        orient: 'left',
        label: "Number of cases",
        tickFormat: function(e){return e/1e3+"k"}
      }],

      /* --- Annotate --- */
      oLabel: true
    }

    return frameProps;
  }

  render(){
    /* 'ref' allows to reset Typeahead */
    const ref = React.createRef();

    /* Returns objects to be passed as props to each graph*/
    const totalCasesProps = Object.assign({}, this.getGraphData('Active'), this.getGraphSpecificProperties('totalCases'), this.getGraphCommonProps());
    const deathCasesProps = Object.assign({}, this.getGraphData('Deaths'), this.getGraphSpecificProperties('deathsCases'), this.getGraphCommonProps());
    const totalCasesSmallDevices = Object.assign({}, this.getGraphDataEvery3Days('Active'), this.getGraphSpecificProperties('totalCases'), this.getGraphCommonProps());
    const deathCasesSmallDevices = Object.assign({}, this.getGraphDataEvery3Days('Deaths'), this.getGraphSpecificProperties('deathsCases'), this.getGraphCommonProps());

    return(
      <div className="col-12">

        <div className="row d-flex justify-content-center align-items-center my-3">
          <span className="icon">
            <img src={require('../images/graph_img.png')} alt="icon graph"/>
          </span>
          <h3>{translate('graphs')}</h3>
        </div>

        <div id="graphs">
          <ResponsiveOrdinalFrame
            {...totalCasesProps}
            responsiveWidth={true}
            showLinePoints={true}
            tooltipContent={d => (
              <div className="tooltip-content">
                <p>{translate('date')}{d.date.split('T')[0]}</p>
                <p>{translate('num_cases')}: {d.totalForTooltip}</p>
              </div>
            )}
          />

          <ResponsiveOrdinalFrame
            {...deathCasesProps}
            responsiveWidth={true}
            showLinePoints={true}
            tooltipContent={d => (
              <div className="tooltip-content">
                <p>{translate('date')}{d.date.split('T')[0]}</p>
                <p>{translate('num_cases')}: {d.totalForTooltip}</p>
              </div>
            )}
          />
        </div>

        <div id="graph-for-mobile">
          <ResponsiveOrdinalFrame
            {...totalCasesSmallDevices}
            responsiveWidth={true}
            showLinePoints={true}
            tooltipContent={d => (
              <div className="tooltip-content">
                <p>{translate('date')}{d.date.split('T')[0]}</p>
                <p>{translate('num_cases')}: {d.totalForTooltip}</p>
              </div>
            )}
          />

          <ResponsiveOrdinalFrame
            {...deathCasesSmallDevices}
            responsiveWidth={true}
            showLinePoints={true}
            tooltipContent={d => (
              <div className="tooltip-content">
                <p>{translate('date')}{d.date.split('T')[0]}</p>
                <p>{translate('num_cases')}: {d.totalForTooltip}</p>
              </div>
            )}
          />
        </div>

        <div className="row d-flex">
          <div className="col-6 col-lg-4 offset-lg-2">

            <Typeahead
              id={2}
              ref={ref}
              onChange={(selected) => {
                this.props.history.push(`/${selected[0]}`)
                ref.current.clear()
              }}
              placeholder="Search another country"
              dropup={true}
              options={this.state.countries}
            />
          </div>

          <div className="col-6 d-flex justify-content-center pb-3">
            <Link to={'/'}>
              <button type="button" className="btn btn-dark px-5">{translate('go_back')}</button>
            </Link>
          </div>

        </div>
      </div>
    )
  }
}

export default withRouter(GraphCountry);
